<template>
  <div>
    <v-card>
      <v-card-title
        >暫停 {{ pauseItem.itemName }}
        <v-btn
          depressed
          outlined
          small
          class="ml-3"
          color="primary"
          disabled
          id="custom-disabled"
          >版本{{ pauseItem.versionNumber }}</v-btn
        ></v-card-title
      >
      <v-card-text
        >已在處理中的規格品因轉檔作業中無法暫停，<br />僅排隊待處理的規格品可暫停，<br />確定要暫停處理嗎?</v-card-text
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="pauseDialogClose()">{{
          $t("form.actions.cancel")
        }}</v-btn>
        <v-btn dark color="primary" @click="pauseConfirm()"
          >{{ $t("form.actions.ok") }}暫停</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    pauseItem: {
      type: Object,
    },
  },
  methods: {
    pauseDialogClose() {
      this.$emit("pauseDialogClose");
    },
    pauseConfirm() {
      this.$emit("pauseConfirm");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles.scss";
#custom-disabled.v-btn--disabled {
  color: #006ad8 !important;
}
</style>
