<template>
  <div>
    <v-card>
      <v-card-title
        >繼續處理 {{ continueItem.itemName }}
        <v-btn
          depressed
          outlined
          small
          class="ml-3"
          color="primary"
          disabled
          id="custom-disabled"
          >版本{{ continueItem.versionNumber }}</v-btn
        ></v-card-title
      >

      <v-card-text v-if="notEnough" class="pb-2"
        >目前剩餘額度 {{ balance }} 點，
      </v-card-text>
      <!-- 餘額足夠版 -->
      <v-card-text v-if="!notEnough" class=" pb-0"
        >將使用 {{ continueItem.continueModelCount }}
        <v-progress-circular
          v-if="continueItem.continueModelCount == undefined"
          :size="18"
          :width="3"
          color="#B1B1B1"
          indeterminate
          class="ml-1 mr-1"
        ></v-progress-circular>
        次轉檔額度，確定要繼續處理嗎?
      </v-card-text>
      <!-- 餘額不足版 -->
      <v-card-text v-if="notEnough" class="pt-0 pb-2"
        >需使用 {{ continueItem.continueModelCount }}
        <v-progress-circular
          v-if="continueItem.continueModelCount == undefined"
          :size="18"
          :width="3"
          color="#B1B1B1"
          indeterminate
          class="ml-1 mr-1"
        ></v-progress-circular>
        次轉檔額度，</v-card-text
      ><v-card-text v-if="notEnough" class="pt-0 pb-0"
        >餘額不足請進行加值</v-card-text
      >

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="notEnough" outlined @click="continueDialogClose()"
          >關閉</v-btn
        >
        <v-btn v-if="!notEnough" outlined @click="continueDialogClose()">{{
          $t('form.actions.cancel')
        }}</v-btn>
        <v-btn v-if="!notEnough" dark color="primary" @click="continueConfirm()"
          >{{ $t('form.actions.ok') }}繼續</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import ApiConfig from '../../plugins/ApiConfig.js';
export default {
  props: {
    continueItem: {
      type: Object,
    },
    companyId: {
      type: String,
    },
  },
  data() {
    return {
      left: null,
      notEnough: false,
      balance: null,
    };
  },
  async mounted() {
    await this.loadBalance();
    this.countLeft();
  },
  methods: {
    async loadBalance() {
      var companyId = this.companyId;
      var start = null;
      var end = null;
      var res = await this.$API.api.pc.companyProcessingUsage.getBalance(
        companyId,
        start,
        end,
        ApiConfig.getJsonConfig
      );
      this.balance = res.data;
    },
    countLeft() {
      this.left = this.balance - this.continueItem.continueModelCount;
      if (this.left < 0) {
        this.notEnough = true;
      }
    },
    continueDialogClose() {
      this.$emit('continueDialogClose');
    },
    continueConfirm() {
      this.$emit('continueConfirm');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles.scss';
#custom-disabled.v-btn--disabled {
  color: #006ad8 !important;
}
</style>
