<template>
  <div>
    <v-card>
      <v-card-title
        >作廢 {{ voidItem.itemName }}
        <v-btn
          v-if="voidItem.versionNumber !== undefined"
          depressed
          outlined
          small
          class="ml-3"
          color="primary"
          disabled
          id="custom-disabled"
          >版本{{ voidItem.versionNumber }}</v-btn
        ></v-card-title
      >
      <v-card-text>確定要作廢嗎?</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="voidDialogClose()">{{
          $t('form.actions.cancel')
        }}</v-btn>
        <v-btn dark color="primary" @click="voidConfirm()"
          >{{ $t('form.actions.ok') }}作廢</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    voidItem: {
      type: Object,
    },
  },
  methods: {
    voidDialogClose() {
      this.$emit('voidDialogClose');
    },
    voidConfirm() {
      this.$emit('voidConfirm');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles.scss';
#custom-disabled.v-btn--disabled {
  color: #006ad8 !important;
}
</style>
