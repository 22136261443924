<template>
  <div>
    <v-card>
      <v-card-title
        >啟用 {{ enableItem.itemName }}
        <v-btn
          depressed
          outlined
          small
          class="ml-3"
          color="primary"
          disabled
          id="custom-disabled"
          >版本{{ enableItem.versionNumber }}</v-btn
        ></v-card-title
      >
      <v-card-text> 啟用後將進入待提交狀態，確定要啟用嗎?</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="enableDialogClose()">{{
          $t('form.actions.cancel')
        }}</v-btn>
        <v-btn dark color="primary" @click="enableConfirm()"
          >{{ $t('form.actions.ok') }}啟用</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    enableItem: {
      type: Object,
    },
  },
  methods: {
    enableDialogClose() {
      this.$emit('enableDialogClose');
    },
    enableConfirm() {
      this.$emit('enableConfirm');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles.scss';
#custom-disabled.v-btn--disabled {
  color: #006ad8 !important;
}
</style>
