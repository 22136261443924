<template>
  <div>
    <v-card>
      <v-card-title
        >提交 {{ pendingItem.itemName }}
        <v-btn
          depressed
          outlined
          small
          class="ml-3"
          color="primary"
          disabled
          id="custom-disabled"
          >版本{{ pendingItem.versionNumber }}</v-btn
        ></v-card-title
      >
      <v-card-text v-if="notEnough" class="pb-2"
        >目前剩餘額度 {{ balance }} 點，
      </v-card-text>
      <!-- 餘額足夠版 -->
      <v-card-text v-if="!notEnough" class=" pb-0"
        >提交後將使用
        {{ pendingItem.pendingAmount }}
        次轉檔額度，確定要提交嗎?</v-card-text
      >
      <!-- 餘額不足版 -->
      <v-card-text v-if="notEnough" class="pt-0 pb-2"
        >提交需使用
        {{ pendingItem.pendingAmount }}
        次轉檔額度，</v-card-text
      ><v-card-text v-if="notEnough" class="pt-0 pb-0"
        >餘額不足請進行加值</v-card-text
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="notEnough" outlined @click="pendingDialogClose()"
          >關閉</v-btn
        >
        <v-btn v-if="!notEnough" outlined @click="pendingDialogClose()">{{
          $t('form.actions.cancel')
        }}</v-btn>
        <v-btn v-if="!notEnough" dark color="primary" @click="pendingConfirm()"
          >{{ $t('form.actions.ok') }}提交</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import ApiConfig from '../../plugins/ApiConfig.js';
export default {
  props: {
    pendingItem: {
      type: Object,
    },
    companyId: {
      type: String,
    },
  },
  data() {
    return {
      left: null,
      notEnough: false,
      balance: null,
    };
  },
  async mounted() {
    await this.loadBalance();
    this.countLeft();
  },
  methods: {
    async loadBalance() {
      var companyId = this.companyId;
      var start = null;
      var end = null;
      var res = await this.$API.api.pc.companyProcessingUsage.getBalance(
        companyId,
        start,
        end,
        ApiConfig.getJsonConfig
      );
      this.balance = res.data;
    },
    countLeft() {
      this.left = this.balance - this.pendingItem.pendingAmount;
      if (this.left < 0) {
        this.notEnough = true;
      }
    },
    pendingDialogClose() {
      this.$emit('pendingDialogClose');
    },
    pendingConfirm() {
      this.$emit('pendingConfirm');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles.scss';
#custom-disabled.v-btn--disabled {
  color: #006ad8 !important;
}
</style>
