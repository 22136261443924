<template>
  <td style="text-align: center">
    <v-chip v-if="this.getState() == 5" color="success" small>
      已完成
    </v-chip>
    <v-chip v-else-if="this.getState() == 0" small>
      待提交
    </v-chip>
    <v-chip v-else-if="this.getState() == 1" small>
      處理中
    </v-chip>
    <v-chip v-else-if="this.getState() == 2" small>
      已暫停
    </v-chip>
    <v-chip v-else-if="this.getState() == 3" color="error" small>
      處理失敗
    </v-chip>
    <v-chip v-else-if="this.getState() == 4" small>
      作廢
    </v-chip>
    <v-chip v-else-if="this.getState() == 6" small>
      待處理
    </v-chip>
  </td>
</template>

<script>
export default {
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    getState() {
      //以 externalId 有無來判斷是否已完成處理
      let items = this.field.split('.');
      let data = this.dataItem;
      items.forEach((item) => {
        data = data[item];
      });
      return data;
    },
  },
};
</script>
