<template>
  <td>
    <span v-if="dataItem.isDefaultModel">
      {{ dataItem.name }} (預設規格品)</span
    >
    <span v-else> {{ dataItem.name }} </span>
    <v-menu
      offset-y
      right
      v-if="dataItem.messages && dataItem.messages.length > 0"
    >
      <template v-slot:activator="{ on }">
        <v-icon
          v-on="on"
          size="20"
          color="error"
          class="ml-1"
          style="margin-top:-3px"
          >mdi-alert</v-icon
        >
      </template>
      <v-card width="280"
        ><v-container
          ><v-row
            class="ma-0 pa-0"
            v-for="(item, index) in dataItem.messages"
            :key="index"
            ><v-col class="pa-0 pb-1"
              ><span style="font-weight:600;color:#898989">[ERROR]</span></v-col
            ><span> {{ item.fieldName }} - {{ item.text }}</span>
          </v-row></v-container
        ></v-card
      >
    </v-menu>
  </td>
</template>

<script>
export default {
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String,
  },
};
</script>
